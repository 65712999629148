import React, { FC } from "react";
import { Icon } from "../Icon";
import { BaseProps } from "../Icon.types";

const IconFacebook: FC<BaseProps> = (props: BaseProps) => {
  return (
    <Icon {...props} viewBox="0 0 448 512">
      <path d="M448 56.7v398.5c0 13.7-11.1 24.7-24.7 24.7H309.1V306.5h58.2l8.7-67.6h-67v-43.2c0-19.6 5.4-32.9 33.5-32.9h35.8v-60.5c-6.2-.8-27.4-2.7-52.2-2.7-51.6 0-87 31.5-87 89.4v49.9h-58.4v67.6h58.4V480H24.7C11.1 480 0 468.9 0 455.3V56.7C0 43.1 11.1 32 24.7 32h398.5c13.7 0 24.8 11.1 24.8 24.7z" />
    </Icon>
  );
};

export default IconFacebook;
