import React, { FC } from "react";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";
import { Props } from "./ThemeWrapper.types";
import { theme } from "../theme";
import {
  h1Styles,
  h2Styles,
  h3Styles,
  h4Styles,
  h5Styles,
  h6Styles,
  bodyStyles,
  bigStyles,
  smallStyles,
} from "../Typography/Typography.styles";

const GlobalStyles = createGlobalStyle`
  ${normalize}

  html, body {
    overflow-x: hidden;
  }

  body {
    color: ${theme.colors.text.body};
    font-size: 112.5%;
    line-height: 1.6;
    font-family: ${theme.fonts.text};

    @media (max-width: ${theme.breakpoints.lg}) {
      font-size: 100%;
    }
  }
  
  h1 { ${h1Styles} }
  h2 { ${h2Styles} }
  h3 { ${h3Styles} }
  h4 { ${h4Styles} }
  h5 { ${h5Styles} }
  h6 { ${h6Styles} }
  p {
     ${bodyStyles}
     margin: ${theme.unit(3)} 0 0;
  }
  em { ${bigStyles} }
  small { ${smallStyles} }

  ::selection {
    background: ${theme.colors.primary.default};
    color: #fff;
  }
  ::-moz-selection {
    background: ${theme.colors.primary.default};
    color: #fff;
  }

  a {
    color: ${theme.colors.text.link};
    transition: color 0.2s;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    strong {
      color: ${theme.colors.text.link};
    }
  }

  * {
    box-sizing: border-box;
  }
`;

export const ThemeWrapper: FC<Props> = ({ children }: Props) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
};
