import React, { FC, ReactNode } from "react";
import styled from "styled-components";
import Flexbox from "../Flexbox";

const StyledSeparator = styled.span<{ $dark?: boolean }>`
  display: block;
  background: ${(p) => (p.$dark ? "#ccc" : "hsla(0, 0%, 100%, 0.4)")};
  height: 1px;
  min-width: 60px;
`;

export const Separator: FC<{ children: ReactNode; dark?: boolean }> = ({
  dark = false,
  children,
}) => {
  return (
    <Flexbox container gutter={2} alignItems="center">
      <Flexbox item>
        <StyledSeparator $dark={dark} />
      </Flexbox>
      <Flexbox item>{children}</Flexbox>
      <Flexbox item>
        <StyledSeparator $dark={dark} />
      </Flexbox>
    </Flexbox>
  );
};
