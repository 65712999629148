import { RefObject, useEffect, useMemo, useState } from "react";

const isBrowser = typeof window !== "undefined";

export default function useOnScreen(
  ref: RefObject<HTMLElement>,
  enabled: boolean = false
) {
  const [isIntersecting, setIntersecting] = useState(false);
  const [boundingClientRectY, setBoundingClientRectY] = useState(0);

  const observer = useMemo(
    () =>
      isBrowser && enabled
        ? new IntersectionObserver(
            ([entry]) => {
              setIntersecting(entry.isIntersecting);
              setBoundingClientRectY(entry.boundingClientRect.y);
            },
            { threshold: Array.from({ length: 100 }, (_, i) => 0.01 * (i + 1)) }
          )
        : null,
    [ref]
  );

  useEffect(() => {
    if (observer) {
      observer.observe(ref.current as Element);
    }
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  return { isIntersecting, boundingClientRectY };
}
