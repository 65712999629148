import React, { FC } from "react";
import styled from "styled-components";
import { Props } from "./Spinner.types";

const StyledContainerDiv = styled.div<Props>`
  ${(p) =>
    p.overlay &&
    `
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 555;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.75);
 `}
`;

const StyledSpinnerDiv = styled.div`
  &,
  &::after {
    border-radius: 50%;
    width: ${(p) => p.theme.unit(20)};
    height: ${(p) => p.theme.unit(20)};
  }

  margin: 0;
  position: relative;
  border-top: ${(p) => p.theme.unit(2.5)} solid rgba(0, 0, 0, 0.15);
  border-right: ${(p) => p.theme.unit(2.5)} solid rgba(0, 0, 0, 0.15);
  border-bottom: ${(p) => p.theme.unit(2.5)} solid rgba(0, 0, 0, 0.15);
  border-left: ${(p) => p.theme.unit(2.5)} solid
    ${(p) => p.theme.colors.primary.default};
  transform: translateZ(0);
  text-indent: -99999px;
  animation: load8 1.1s infinite linear;

  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Spinner: FC<Props> = ({
  overlay = false,
  label = "Loading...",
}) => {
  return (
    <StyledContainerDiv overlay={overlay}>
      <StyledSpinnerDiv>{label}</StyledSpinnerDiv>
    </StyledContainerDiv>
  );
};
