import React, { FC, forwardRef, Ref } from "react";
import styled, { css } from "styled-components";
import { Props } from "./Button.types";

const StyledButtonInner = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const getButtonSizeStyles = (p: any) => {
  switch (p.size) {
    case "xs":
      return `
        padding: 4px 15px 6px;
        font-size: 1rem;
        border-radius: 20px;
      `;
    case "sm":
      return `
        padding: 8px 25px 10px;
        font-size: 1.2rem;
        border-radius: 25px;

        @media (max-width: ${p.theme.breakpoints.md}) {
          padding: 4px 15px 6px;
          font-size: 1rem;
          border-radius: 20px;
        }
      `;
    case "lg":
      return `
        font-size: 1.6rem;
        padding: 15px 40px 18px;
        border-radius: 35px;
        min-width: 225px;

        @media (max-width: ${p.theme.breakpoints.md}) {
          font-size: 1.4rem;
          padding: 12px 30px 15px;
          border-radius: 30px;
          min-width: 160px;
        }
      `;
    case "md":
    default:
      return `
        font-size: 1.4rem;
	      padding: 12px 30px 15px;
	      border-radius: 30px;

        @media (max-width: ${p.theme.breakpoints.md}) {
          padding: 8px 25px 10px;
          font-size: 1.2rem;
          border-radius: 25px;
        }
      `;
  }
};

const primaryStyles = css<Props>`
  border-color: ${(p) => p.theme.colors.primary.default};
  color: ${(p) =>
    p.variant === "primary-light" || p.filled
      ? "#fff"
      : p.theme.colors.primary.default};

  &:before,
  &:after {
    background-color: ${(p) => p.theme.colors.primary.default};
  }

  &:hover,
  &:active,
  &:focus {
    color: ${(p) =>
      p.filled && p.variant !== "primary-light"
        ? p.theme.colors.primary.default
        : "rgba(255,255,255,0.8)"};
  }
`;

const secondaryStyles = css<Props>`
  border-color: ${(p) => p.theme.colors.secondary.default};
  color: ${(p) =>
    p.variant === "secondary-light" || p.filled
      ? "#fff"
      : p.theme.colors.secondary.default};

  &:before,
  &:after {
    background-color: ${(p) => p.theme.colors.secondary.default};
  }

  &:hover,
  &:active,
  &:focus {
    color: ${(p) =>
      p.filled && p.variant !== "secondary-light"
        ? p.theme.colors.secondary.default
        : "rgba(255,255,255,0.75)"};
  }
`;

const lightStyles = css<Props>`
  border-color: #fff;
  color: ${(p) => (p.filled ? p.theme.colors.primary.default : "#fff")};

  &:before,
  &:after {
    background-color: #fff;
  }

  &:hover,
  &:active,
  &:focus {
    color: ${(p) =>
      p.filled ? "rgba(255,255,255,0.75)" : p.theme.colors.primary.default};
  }
`;

const StyledButton = styled.button<Props>`
  font-family: ${(p) => p.theme.fonts.headings};
  text-align: center;
  background: transparent;
  position: relative;
  z-index: 1;
  border: 4px solid;
  transition: color 0.3s, background 0.3s, border 0.3s, opacity 0.3s;
  line-height: 1.2;
  cursor: pointer;
  text-decoration: none !important;
  overflow: hidden;
  ${getButtonSizeStyles}
  ${(p) =>
    p.fullWidth ? "display: block; width: 100%;" : "display: inline-block;"}

  &:before, &:after {
    content: "";
    position: absolute;
    z-index: -1;
    top: -10px;
    left: -40px;
    bottom: -10px;
    right: 40%;
    transform-origin: 0 50%;
    transform: skewX(-12deg) translateX(${(p) => (p.filled ? "0" : "-100%")});
    transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  &:after {
    left: 40%;
    right: -40px;
    transform-origin: 100% 50%;
    transform: skewX(-12deg) translateX(${(p) => (p.filled ? "0" : "100%")});
  }

  &:hover,
  &:active,
  &:focus {
    &:before {
      transform: skew(-12deg) translateX(${(p) => (p.filled ? "-100%" : "0")});
    }
    &:after {
      transform: skew(-12deg) translateX(${(p) => (p.filled ? "100%" : "0")});
    }
  }

  ${(p) =>
    ["primary", "primary-light"].includes(p.variant as string) && primaryStyles}
  ${(p) =>
    ["secondary", "secondary-light"].includes(p.variant as string) &&
    secondaryStyles}
  ${(p) => p.variant === "light" && lightStyles}
`;

export const Button: FC<Props> = forwardRef(
  (
    { children, variant = "primary", filled = false, ...props }: Props,
    ref: Ref<any>
  ) => {
    return (
      <StyledButton variant={variant} filled={filled} {...props} ref={ref}>
        <StyledButtonInner>{children}</StyledButtonInner>
      </StyledButton>
    );
  }
);
