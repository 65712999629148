import React, { forwardRef } from "react";
import styled from "styled-components";
import FormLabel from "../../FormLabel";
import Typography from "../../Typography";
import { Props } from "./InputTextarea.types";
import { inputStyles } from "../Input.styles";

const StyledTextarea = styled.textarea<any>`
  ${inputStyles}
  width: 100% !important;
`;

export const InputTextarea = forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    const {
      autoFocus,
      disabled,
      error,
      label,
      id,
      name,
      placeholder,
      value,
      required,
      rows = 3,
      onBlur,
      onChange,
      onClick,
      onFocus,
      onMouseLeave,
      onKeyDown,
      onKeyPress,
      onKeyUp,
      ...rest
    } = props;

    return (
      <FormLabel label={label}>
        <StyledTextarea
          {...{
            autoFocus,
            disabled,
            label,
            id,
            name,
            placeholder,
            value,
            required,
            rows,
            onBlur,
            onChange,
            onClick,
            onFocus,
            onMouseLeave,
            onKeyDown,
            onKeyPress,
            onKeyUp,
            ref,
          }}
          {...(required ? { "aria-required": true } : {})}
          {...(error && error !== "" ? { "aria-invalid": true } : {})}
          {...rest}
        />
        {error && (
          <Typography type="small" color={(c) => c.danger.default}>
            {error}
          </Typography>
        )}
      </FormLabel>
    );
  }
);
