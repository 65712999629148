import React, { forwardRef, Ref } from "react";
import { omit } from "ramda";
import styled from "styled-components";
import { InternalProps, StyledBaseProps } from "./Icon.types";

const CleanSvg = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(
  (props, ref) => (
    <svg ref={ref} {...omit(["size", "fillFn", "inline"])(props)} />
  )
);

const StyledIconBase = styled(CleanSvg)<StyledBaseProps>`
  ${(p) => {
    const size = p.size || 5;
    const fill = p.fillFn ? p.fillFn(p.theme.colors) : "currentColor";
    return `
      user-select: none;
      width: ${p.theme.unit(size)};
      height: ${p.theme.unit(size)};
      fill: ${fill};
      flex-shrink: 0;
      display: ${p.inline ? "inline-block" : "block"};
      ${p.inline ? "vertical-align: middle;" : ""}
    `;
  }}
`;

export const Icon: React.FC<InternalProps> = forwardRef(
  (props: InternalProps, ref: Ref<any>) => {
    const {
      children,
      size = 5,
      viewBox = "0 0 24 24",
      fill,
      inline,
      ...rest
    } = props;

    return (
      <StyledIconBase
        preserveAspectRatio="xMidYMid meet"
        focusable="false"
        aria-hidden="true"
        role="presentation"
        size={size}
        viewBox={viewBox}
        inline={inline}
        fillFn={fill}
        ref={ref}
        {...rest}
      >
        {children}
      </StyledIconBase>
    );
  }
);
