import React, { forwardRef } from "react";
import styled from "styled-components";
import FormLabel from "../../FormLabel";
import Typography from "../../Typography";
import { Props } from "./InputText.types";
import { inputStyles } from "../Input.styles";

const StyledInput = styled.input<any>`
  ${inputStyles}
`;

export const InputText = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    autoFocus,
    disabled,
    error,
    label,
    id,
    name,
    placeholder,
    value,
    required,
    type = "text",
    onBlur,
    onChange,
    onClick,
    onFocus,
    onMouseLeave,
    onKeyDown,
    onKeyPress,
    onKeyUp,
    ...rest
  } = props;

  return (
    <FormLabel label={label}>
      <StyledInput
        {...{
          type,
          autoFocus,
          disabled,
          label,
          id,
          name,
          placeholder,
          value,
          required,
          onBlur,
          onChange,
          onClick,
          onFocus,
          onMouseLeave,
          onKeyDown,
          onKeyPress,
          onKeyUp,
          ref,
        }}
        {...(required ? { "aria-required": true } : {})}
        {...(error && error !== "" ? { "aria-invalid": true } : {})}
        {...rest}
      />
      {error && (
        <Typography type="small" color={(c) => c.danger.default}>
          {error}
        </Typography>
      )}
    </FormLabel>
  );
});
