import IconFacebook from "./components/IconFacebook";
import IconInstagram from "./components/IconInstagram";
import IconInfo from "./components/IconInfo";
import IconScrollDown from "./components/IconScrollDown";
import ChevronLeft from "./components/IconChevronLeft";
import ChevronRight from "./components/IconChevronRight";

export default {
  IconFacebook,
  IconInstagram,
  IconInfo,
  IconScrollDown,
  ChevronLeft,
  ChevronRight,
};
