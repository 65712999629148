import { css } from "styled-components";

export const h1Styles = css`
  margin: 0;
  font: 700 4.4rem/1.2 ${(p) => p.theme.fonts.headings};

  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    font-size: 3.2rem;
  }

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    font-size: 2.6rem;
  }
`;

export const h2Styles = css`
  margin: 0;
  font: 700 3rem/1.2 ${(p) => p.theme.fonts.headings};

  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    font-size: 2.2rem;
  }

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    font-size: 2rem;
  }
`;

export const h3Styles = css`
  margin: 0;
  font: 700 2.4rem/1.2 ${(p) => p.theme.fonts.headings};

  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    font-size: 1.8rem;
  }

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    font-size: 1.6rem;
  }
`;

export const h4Styles = css`
  margin: 0;
  font: 700 2rem/1.2 ${(p) => p.theme.fonts.headings};

  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    font-size: 1.6rem;
  }

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    font-size: 1.4rem;
  }
`;

export const h5Styles = css`
  margin: 0;
  font: 700 1.6rem/1.2 ${(p) => p.theme.fonts.headings};

  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    font-size: 1.4rem;
  }

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    font-size: 1.2rem;
  }
`;

export const h6Styles = css`
  margin: 0;
  font: 700 1.2rem/1.2 ${(p) => p.theme.fonts.headings};

  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    font-size: 1.125rem;
  }

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    font-size: 1rem;
  }
`;

export const bodyStyles = css`
  margin: 0;
  font: 400 1.125rem/1.6 ${(p) => p.theme.fonts.text};

  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    font-size: 1rem;
  }
`;

export const body2Styles = css`
  margin: 0;
  font: 400 1rem/1.4 ${(p) => p.theme.fonts.text};

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    font-size: 0.925rem;
  }
`;

export const smallStyles = css`
  margin: 0;
  font: 400 0.75rem/1.6 ${(p) => p.theme.fonts.text};
`;

export const bigStyles = css`
  margin: 0;
  font: 400 1.4rem/1.5 ${(p) => p.theme.fonts.text};

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    font-size: 1.2rem;
  }
`;
