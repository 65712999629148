import React, { FC, useMemo } from "react";
import styled from "styled-components";
import Flexbox from "../Flexbox";
import Typography from "../Typography";
import { Props } from "./PriceList.types";

const PriceListItemHead = styled.header`
  background: #5c063a;
  color: #fff;
  padding: 10px 15px 5px;
`;

const PriceListContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const PriceListItemBodyPart = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:nth-child(even) {
    background: #f7f7f7;
  }
`;

const PriceListItemFoot = styled.div`
  border-top: 4px solid ${(p) => p.theme.colors.primary.default};
  border-bottom: 3px solid #e7e7e7;
  padding: 10px 15px 8px;
  font-size: 1.8rem;
  font-weight: 700;
`;

const PriceListItem = styled(Flexbox)<{ $popular: boolean }>`
  ${PriceListItemHead} {
    ${(p) =>
      p.$popular &&
      `margin-top: -19.5px; background: ${p.theme.colors.secondary.default};`}
  }

  ${PriceListItemFoot} {
    ${(p) =>
      p.$popular &&
      `padding-top: 19px; padding-bottom: 19px; border-top-color: ${p.theme.colors.secondary.default};`}
  }
`;

export const PriceList: FC<Props> = ({ items }) => {
  const bodyPartsArr = useMemo(() => {
    const max = items.reduce(
      (acc, item) =>
        item.bodyParts.length > acc ? item.bodyParts.length : acc,
      0
    );
    return Array.from({ length: max }, (_, i) => i);
  }, [items]);

  return (
    <Flexbox
      container
      wrap="wrap"
      direction="column"
      gutter={4}
      md={{ direction: "row", gutter: 0 }}
    >
      {items.map(({ mostPopularLabel, price, bodyParts, title }) => (
        <PriceListItem
          key={title}
          $popular={!!mostPopularLabel}
          item
          flex="1"
          md={{ flex: "0 0 33.33%" }}
          xl={{ flex: "1" }}
        >
          <PriceListItemHead>
            {mostPopularLabel && (
              <Typography type="small" as="div" textAlign="center">
                {mostPopularLabel}
              </Typography>
            )}
            <Typography type="h5" weight="700" as="h6" textAlign="center">
              {title}
            </Typography>
          </PriceListItemHead>
          <PriceListContent>
            {bodyPartsArr.map((index) => (
              <PriceListItemBodyPart key={index}>
                <Typography type="body" as="div" textAlign="center">
                  {bodyParts[index]}
                </Typography>
              </PriceListItemBodyPart>
            ))}
          </PriceListContent>
          <PriceListItemFoot>
            <Typography type="h5" as="div" weight="700" textAlign="center">
              {price}
            </Typography>
          </PriceListItemFoot>
        </PriceListItem>
      ))}
    </Flexbox>
  );
};
