import { Link } from "gatsby";
import React, { FC } from "react";
import styled from "styled-components";
import { Theme } from "../theme.types";
import Flexbox from "../Flexbox";
import Typography from "../Typography";
import { Props, BoxProps } from "./MassageBoxes.types";

const MassageBoxText = styled.div`
  transform: translateY(0);
  transition: transform 0.2s;
`;

const MassageBoxSeparator = styled.div`
  display: block;
  height: 1px;
  background: rgba(255, 255, 255, 0.8);
  transform: translateY(25px);
  transition: transform 0.2s;
  margin-top: -10px;
`;

const MassageBoxReadMoreButton = styled.div`
  display: block;
  transform: translateY(100%);
  opacity: 0;
  font-size: 1.2rem;
  transition: opacity 0.2s, transform 0.2s;
`;

const StyledLink = styled(Link)`
  text-decoration: none !important;
`;

const MassageBoxBackground = styled.div`
  grid-area: 1/1;

  & .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: transform 0.05s;
  }
`;

const MassageBoxBackgroundOverlay = styled.div`
  grid-area: 1/1;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
`;

const MassageBoxInner = styled.div<{
  $overlayColor?: (colors: Theme["colors"]) => string;
}>`
  color: #fff;
  padding: 30px;
  padding-top: 100px;
  position: relative;
  z-index: 1;
  height: 100%;
  grid-area: 1/1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${(p) =>
      p.$overlayColor
        ? p.$overlayColor(p.theme.colros)
        : p.theme.colors.primary.default};
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 0.2s, transform 0.2s;
    z-index: -1;
  }
`;

const MassageBoxWrapper = styled.article`
  position: relative;
  z-index: 1;
  overflow: hidden;
  height: 100%;
  display: grid;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: -1;
  }

  &:hover {
    ${MassageBoxInner} {
      &:before {
        opacity: 0.8;
        transform: translateY(0);
      }

      ${MassageBoxText} {
        transform: translateY(-70px);
      }

      ${MassageBoxReadMoreButton} {
        transform: translateY(0);
        opacity: 1;
      }

      ${MassageBoxSeparator} {
        transform: translateY(-10px);
      }
    }
  }
`;

const StyledFlexboxItem = styled(Flexbox)`
  padding-bottom: ${(p) => p.theme.unit(8)};

  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    padding-bottom: ${(p) => p.theme.unit(0)};
  }
`;

export const MassageBoxes: FC<Props> = ({ massages }) => {
  return (
    <Flexbox
      container
      gutter={4}
      wrap="wrap"
      direction="column"
      md={{ direction: "row" }}
    >
      {massages.map(({ title, description, background, link }) => (
        <StyledFlexboxItem
          key={title}
          item
          flex="1"
          md={{ flex: "0 0 50%" }}
          xl={{ flex: "0 0 33.33%" }}
        >
          <MassageBox
            title={title}
            description={description}
            readMoreLabel="Les mer"
            backgroundImage={background}
            to={link}
          />
        </StyledFlexboxItem>
      ))}
    </Flexbox>
  );
};

export const MassageBox: FC<BoxProps> = ({
  title,
  description,
  readMoreLabel,
  backgroundImage,
  overlayColor,
  to,
}) => {
  return (
    <StyledLink to={to}>
      <MassageBoxWrapper>
        {backgroundImage && (
          <>
            <MassageBoxBackground>{backgroundImage}</MassageBoxBackground>
            <MassageBoxBackgroundOverlay />
          </>
        )}
        <MassageBoxInner $overlayColor={overlayColor}>
          <MassageBoxText>
            <Typography type="h4" as="h3" color={() => "#fff"}>
              {title}
            </Typography>
            <Typography type="body" as="p" color={() => "#fff"}>
              {description}
            </Typography>
          </MassageBoxText>
          <MassageBoxSeparator />
          <MassageBoxReadMoreButton>
            <Typography type="h6" as="span">
              {readMoreLabel}
            </Typography>
          </MassageBoxReadMoreButton>
        </MassageBoxInner>
      </MassageBoxWrapper>
    </StyledLink>
  );
};
