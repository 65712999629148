import React, { FC, ReactNode, useState } from "react";
import styled from "styled-components";
import { Script } from "gatsby";

import { ThemeWrapper } from "../shared";
import { Footer } from "./Footer";
import { Header, HEADER_WIDTH } from "./Header";

const StyledLayout = styled.div<{ $isHeaderOpen: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  transition: transform 0.2s;
  position: relative;
  transform: translateX(
    ${(p) => (p.$isHeaderOpen ? `${HEADER_WIDTH - 1}px` : "0")}
  );

  @media (min-width: ${(p) => p.theme.breakpoints.lg}) {
    transform: none !important;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 99;
    visibility: ${(p) => (p.$isHeaderOpen ? `visible` : "hidden")};
    opacity: ${(p) => (p.$isHeaderOpen ? `1` : "0")};
    transition: opacity 0.2s, visibility 0.2s;

    @media (min-width: ${(p) => p.theme.breakpoints.lg}) {
      display: none !important;
    }
  }
`;

const Layout: FC<{ children: ReactNode; location: any }> = ({ children }) => {
  const [isHeaderOpen, setIsHeaderOpen] = useState(false);

  return (
    <ThemeWrapper>
      <Header isOpen={isHeaderOpen} onOpenChange={setIsHeaderOpen} />
      <StyledLayout $isHeaderOpen={isHeaderOpen}>
        <main>{children}</main>
        <Footer />
      </StyledLayout>

      <div id="fb-root"></div>
      <Script
        id="facebook-jssdk"
        src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.1&appId=2055053608100090"
      />
    </ThemeWrapper>
  );
};

export default Layout;
