import { css } from "styled-components";

export const inputStyles = css`
  display: block;
  padding: ${(p) => p.theme.unit(3)};
  width: 100%;
  margin: 0;
  background: ${(p) => p.theme.colors.primary.light};
  color: ${(p) => p.theme.colors.text.body};
  border: 4px solid transparent;
  transition: background 0.3s, border 0.3s;

  &:focus,
  &:active {
    outline: none;
    background: #ddd;
    border-color: ${(p) => p.theme.colors.secondary.default};
  }

  ::placeholder {
    color: #999;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: #999;
  }

  ::-ms-input-placeholder {
    color: #999;
  }
`;
