import { Theme } from "./theme.types";

const colorPrimary = {
  default: "#5c063a",
  dark: "#44042b",
};

const colorSecondary = {
  default: "#a6bc37",
};

const colorSuccess = {
  default: "rgb(12, 182, 12)",
};

const colorDanger = {
  default: "#da5141",
};

const colors = {
  primary: colorPrimary,
  secondary: colorSecondary,
  success: colorSuccess,
  danger: colorDanger,
  text: {
    heading: "#212529",
    body: "#212529",
    link: colorPrimary.default,
    accent: colorSecondary.default,
  },
};

const breakpoints = {
  xl: "1200px",
  lg: "980px",
  md: "768px",
  sm: "540px",
  xs: "320px",
};

const zIndexes = {
  header: 999,
  mainNav: 998,
};

const fonts = {
  headings: `'Righteous', cursive`,
  text: `'Open Sans', Helvetica, Arial, sans-serif`,
};

export const theme: Theme = {
  colors,
  breakpoints,
  zIndexes,
  fonts,
  unit: (size) => `${size * 4}px`,
};
