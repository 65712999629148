import React, { FC } from "react";
import styled, { css } from "styled-components";
import { Props } from "./Container.types";

const fullHeightStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
`;

const StyledContainer = styled.div<{ $fullHeight?: boolean }>`
  ${(p) => p.$fullHeight && fullHeightStyles}
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 ${(p) => p.theme.unit(6)};

  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    padding: 0 ${(p) => p.theme.unit(4)};
  }
`;

export const Container: FC<Props> = ({ children, fullHeight, ...props }) => {
  return (
    <StyledContainer $fullHeight={fullHeight} {...props}>
      {children}
    </StyledContainer>
  );
};
