import React from "react";
import styled from "styled-components";
import Typography from "../Typography";
import { Props } from "./FormLabel.types";

const StyledLabel = styled.label``;

const StyledTypography = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  font-size: 0.875em;
`;

export const FormLabel: React.FC<Props> = ({ children, label, htmlFor }) => (
  <StyledLabel htmlFor={htmlFor}>
    <StyledTypography type="strong" color={() => "#fff"}>
      {label}
    </StyledTypography>
    {children}
  </StyledLabel>
);
