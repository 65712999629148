import React, { FC, useRef } from "react";
import { Link } from "gatsby";
import styled, { createGlobalStyle } from "styled-components";

import { Icon, Typography } from "../../shared";
import { useOnClickOutside } from "../../shared/hooks";

export const HEADER_WIDTH = 250;
export const HEADER_BUTTON_WIDTH = 80;
export const HEADER_MOBILE_HEIGHT = 60;

const GlobalStyles = createGlobalStyle<{ $open: boolean }>`
  body {
    padding-left: ${HEADER_BUTTON_WIDTH}px;

    @media (min-width: ${(p) => p.theme.breakpoints.lg}) {
      padding-left: ${HEADER_WIDTH}px;
    }

    @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
      padding-left: 0;
      padding-top: ${HEADER_MOBILE_HEIGHT}px;
    }
  }
`;

const StyledHeader = styled.header<{ $open: boolean }>`
  position: fixed;
  background: ${(p) => p.theme.colors.primary.default};
  width: ${HEADER_WIDTH}px;
  padding: ${(p) => p.theme.unit(10)} ${(p) => p.theme.unit(7.5)};
  top: 0;
  left: 0;
  bottom: 0;
  box-shadow: 0 0 25px rgb(0 0 0 / 10%);
  z-index: 999;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.2s;
  transform: translateX(${(p) => (p.$open ? "0px" : `-${HEADER_WIDTH}px`)});
  @media (min-width: ${(p) => p.theme.breakpoints.lg}) {
    transform: translateX(0) !important;
  }
`;

const Logo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  perspective: 300px;

  img {
    max-width: 140px;
    transition: opacity 0.3s, transform 0.3s;
  }

  &:hover {
    img {
      transform: rotateY(1turn);
      opacity: 0.6;
    }
  }
`;

const Nav = styled.nav`
  font-family: ${(p) => p.theme.fonts.headings};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ul {
    list-style: none;
    margin: ${(p) => p.theme.unit(5)} 0;
    padding: 0;
    font-size: 1.4rem;

    li + li {
      margin-top: ${(p) => p.theme.unit(5)};
    }
  }

  a {
    color: hsla(0, 0%, 100%, 0.6);
    border-bottom: 3px solid transparent;
    position: relative;
    text-decoration: none;

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      background: #fff;
      transform: scaleX(0);
      transition: transform 0.3s;
    }

    &:hover {
      color: #fff;

      &:before {
        transform: scaleX(1);
      }
    }

    &.active {
      color: ${(p) => p.theme.colors.secondary.default};

      &:before {
        background-color: ${(p) => p.theme.colors.secondary.default};
        transform: scaleX(1);
      }
    }
  }
`;

const Aside = styled.aside`
  color: rgba(255, 255, 255, 0.4);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  nav {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
    }

    li {
      margin: 10px 8px;
    }

    svg {
      transition: fill 0.2s;
    }

    a:hover {
      svg {
        fill: ${(p) => p.theme.colors.secondary.default};
      }
    }
  }
`;

const HeaderButtonIcon = styled.div`
  flex: 1;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  max-width: 40px;

  span {
    display: block;
    width: 100%;
    height: 3px;
    background: #fff;
    transition: transform 0.4s, opacity 0.4s;

    &:nth-child(1) {
      transform-origin: 0 50% 0;
    }

    &:nth-child(3) {
      transform-origin: 0 50% 0;
    }

    & + span {
      margin-top: 8px;
    }
  }
`;

const HeaderButton = styled.button<{ $open: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 80px;
  background: ${(p) => p.theme.colors.primary.default};
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  cursor: pointer;
  box-shadow: 0 0 25px rgb(0 0 0 / 15%);
  transition: transform 0.2s, background 0.2s;
  ${(p) => p.$open && `background: ${p.theme.colors.primary.dark};`}
  ${(p) => p.$open && `transform: translateX(${HEADER_WIDTH}px);`}

  @media (min-width: ${(p) => p.theme.breakpoints.lg}) {
    display: none !important;
  }

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    bottom: auto;
    right: 0;
    width: auto;
    height: ${HEADER_MOBILE_HEIGHT}px;
  }

  ${HeaderButtonIcon} {
    ${(p) =>
      p.$open &&
      `
      span:nth-child(1) {
        transform: translate(7px,-3px) rotate(45deg) !important;
      }
      span:nth-child(2) {
        transform: scaleX(0) !important;
        opacity: 0;
      }
      span:nth-child(3) {
        transform: translate(7px,3px) rotate(-45deg) !important;
      }
    `}
  }

  &:hover {
    background: ${(p) => p.theme.colors.primary.dark};

    ${HeaderButtonIcon} {
      span:nth-child(1) {
        transform: translateY(-4px);
      }
      span:nth-child(3) {
        transform: translateY(4px);
      }
    }
  }
`;

type Props = {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
};

const navLinks = [
  { label: "Hiem", link: "/" },
  { label: "Prisliste", link: "/prisliste" },
  { label: "Om oss", link: "/om-oss" },
  { label: "Massasje", link: "/massasje" },
  { label: "Kontakt", link: "/kontakt" },
];

export const Header: FC<Props> = ({ isOpen, onOpenChange, ...props }) => {
  const headerRef = useRef(null);
  const headerButtonRef = useRef(null);

  useOnClickOutside([headerRef, headerButtonRef], () => {
    onOpenChange(false);
  });

  return (
    <>
      <GlobalStyles $open={isOpen} />
      <HeaderButton
        ref={headerButtonRef}
        $open={isOpen}
        onClick={() => onOpenChange(!isOpen)}
      >
        <HeaderButtonIcon>
          <span />
          <span />
          <span />
        </HeaderButtonIcon>
      </HeaderButton>
      <StyledHeader $open={isOpen} ref={headerRef} {...props}>
        <Logo>
          <Link to="/">
            <img src="/logo.png" alt="SolarMassasje Logo" />
          </Link>
        </Logo>
        <Nav>
          <ul>
            {navLinks.map(({ label, link }) => (
              <li key={label}>
                <Link
                  to={link}
                  activeClassName="active"
                  onClick={() => onOpenChange(false)}
                >
                  {label}
                </Link>
              </li>
            ))}
          </ul>
        </Nav>
        <Aside>
          <nav>
            <ul>
              <li>
                <Link
                  to="https://www.facebook.com/solarmassasje/"
                  target="_blank"
                >
                  <Icon.IconFacebook size={8} fill={() => "#fff"} />
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.instagram.com/solarmassasje/"
                  target="_blank"
                >
                  <Icon.IconInstagram size={8} fill={() => "#fff"} />
                </Link>
              </li>
            </ul>
          </nav>
          <Typography type="small">Copyright © Solar Massasje 2023</Typography>
        </Aside>
      </StyledHeader>
    </>
  );
};
