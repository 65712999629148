import React, { FC } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import {
  Box,
  Button,
  Container,
  Flexbox,
  Icon,
  Typography,
} from "../../shared";

const StyledFooter = styled.footer`
  color: #fff;
  background: ${(p) => p.theme.colors.primary.dark};
  padding: 40px 0;

  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    padding: 20px 0;
  }

  a {
    color: #fff;
    font-weight: 700;
  }
`;

export const Footer: FC = (props) => {
  return (
    <StyledFooter {...props}>
      <Container>
        <Flexbox
          container
          gutter={4}
          direction="column"
          md={{ direction: "row" }}
        >
          <Flexbox item size={12} md={{ size: 4 }}>
            <Flexbox container gutter={1} alignItems="center">
              <Flexbox item>
                <Icon.IconFacebook fill={() => "#fff"} size={9} />
              </Flexbox>
              <Flexbox item>
                <Typography type="h5" as="h5">
                  Facebook
                </Typography>
              </Flexbox>
            </Flexbox>

            <Box mb={4} />

            <div
              className="fb-page"
              data-href="https://www.facebook.com/solarmassasje"
              data-tabs="timeline"
              data-height="70"
              data-small-header="false"
              data-adapt-container-width="true"
              data-hide-cover="false"
              data-show-facepile="true"
            >
              <blockquote
                cite="https://www.facebook.com/solarmassasje"
                className="fb-xfbml-parse-ignore"
              >
                <a href="https://www.facebook.com/solarmassasje">
                  Solar Massasje
                </a>
              </blockquote>
            </div>
          </Flexbox>

          <Flexbox item size={12} md={{ size: 4 }}>
            <Flexbox container gutter={1} alignItems="center">
              <Flexbox item>
                <Icon.IconInstagram fill={() => "#fff"} size={9} />
              </Flexbox>
              <Flexbox item>
                <Typography type="h5" as="h5">
                  Instagram
                </Typography>
              </Flexbox>
            </Flexbox>
            <Box mb={4} />
            <Link to="https://www.instagram.com/solarmassasje/" target="_blank">
              <Button variant="light" size="sm">
                Follow
              </Button>
            </Link>
          </Flexbox>

          <Flexbox item size={12} md={{ size: 4 }}>
            <Flexbox container gutter={1} alignItems="center">
              <Flexbox item>
                <Icon.IconInfo fill={() => "#fff"} size={9} />
              </Flexbox>
              <Flexbox item>
                <Typography type="h5" as="h5">
                  Kontakt
                </Typography>
              </Flexbox>
            </Flexbox>
            <Box mt={2} mb={1}>
              <Typography
                type="body2"
                as="span"
                color={() => `#fff`}
                weight="700"
              >
                Adresse:
              </Typography>
            </Box>
            <Typography
              type="body2"
              as="p"
              color={() => `hsla(0, 0%, 100%, .6);`}
            >
              Heigata 93, 3920 Porsgrunn Norge
            </Typography>

            <Box mt={2} mb={1}>
              <Typography
                type="body2"
                as="span"
                color={() => `#fff`}
                weight="700"
              >
                Åpningstider:
              </Typography>
            </Box>
            <Typography
              type="body2"
              as="p"
              color={() => `hsla(0, 0%, 100%, .6);`}
            >
              Mandag-Fredag 09:00-18:00
              <br />
              Lørdag 10:00-16:00
            </Typography>

            <Box mt={2} mb={1}>
              <Typography
                type="body2"
                as="span"
                color={() => `#fff`}
                weight="700"
              >
                Kontaktinfo:
              </Typography>
            </Box>
            <Typography
              type="body2"
              as="p"
              color={() => `hsla(0, 0%, 100%, .6);`}
            >
              Mob: <Link to="tel:954-15-957">(+47) 954-15-957</Link>
              <br />
              Email:{" "}
              <Link to="mailto:lana@solarmassasje.no">
                lana@solarmassasje.no
              </Link>
            </Typography>
          </Flexbox>
        </Flexbox>
      </Container>
    </StyledFooter>
  );
};
