import React, { FC } from "react";
import { Icon } from "../Icon";
import { BaseProps } from "../Icon.types";

const IconScrollDown: FC<BaseProps> = (props: BaseProps) => {
  return (
    <Icon {...props} viewBox="0 0 42 82">
      <path d="m14.697674,57.197675l0,-5c-5.2,0 -9.5,-4.3 -9.5,-9.5l0,-28c0,-5.2 4.3,-9.5 9.5,-9.5l12,0c5.2,0 9.5,4.3 9.5,9.5l0,28c0,5.2 -4.3,9.5 -9.5,9.5l0,5c8,0 14.5,-6.5 14.5,-14.5l0,-28c0,-8 -6.5,-14.5 -14.5,-14.5l-12,0c-8,0 -14.5,6.5 -14.5,14.5l0,28c0,8 6.5,14.5 14.5,14.5z" />
      <path d="m18.597674,18.097675c0.1,0.1 0.2,0.3 0.3,0.4c0.5,0.5 1.1,0.7 1.8,0.7c0.2,0 0.3,0 0.5,0c0.2,0 0.3,-0.1 0.5,-0.1c0.1,-0.1 0.3,-0.1 0.4,-0.2c0.1,-0.1 0.3,-0.2 0.4,-0.3c0.1,-0.1 0.2,-0.2 0.3,-0.4c0.1,-0.1 0.2,-0.3 0.2,-0.4c0.1,-0.2 0.1,-0.3 0.1,-0.5c0,-0.2 0,-0.3 0,-0.5c0,-0.7 -0.3,-1.3 -0.7,-1.8c-0.1,-0.1 -0.2,-0.2 -0.4,-0.3c-0.1,-0.1 -0.3,-0.2 -0.4,-0.2c-0.2,-0.1 -0.3,-0.1 -0.5,-0.1c-0.8,-0.2 -1.7,0.1 -2.3,0.7c-0.5,0.5 -0.7,1.1 -0.7,1.8c0,0.2 0,0.3 0,0.5c0,0.2 0.1,0.3 0.1,0.5c0.3,-0.1 0.3,0 0.4,0.2z" />
      <circle r="2.5" cy="40.197675" cx="20.697674"></circle>
      <circle r="2.5" cy="28.497675" cx="20.697674"></circle>
      <path d="m18.197674,52.197675c0.1,1.3 1.2,2.2 2.5,2.2s2.3,-1 2.5,-2.2c0,-0.1 0,-0.2 0,-0.2c0,-1.4 -1.1,-2.5 -2.5,-2.5s-2.5,1.1 -2.5,2.5c0,0 0,0.1 0,0.2z" />
      <path d="m18.897674,65.497675c0.1,0.1 0.2,0.2 0.4,0.3c0.1,0.1 0.3,0.2 0.4,0.2c0.2,0.1 0.3,0.1 0.5,0.1c0.2,0 0.3,0 0.5,0c0.7,0 1.3,-0.3 1.8,-0.7c0.5,-0.5 0.7,-1.1 0.7,-1.8s-0.3,-1.3 -0.7,-1.8c-0.9,-0.9 -2.6,-0.9 -3.5,0c-0.5,0.5 -0.7,1.1 -0.7,1.8s0.2,1.4 0.6,1.9z" />
      <path d="m11.897674,69.897675c-1,1 -1,2.6 0,3.5l7,7c0.5,0.5 1.1,0.7 1.8,0.7s1.3,-0.2 1.8,-0.7l7,-7c1,-1 1,-2.6 0,-3.5s-2.6,-1 -3.5,0l-5.3,5.3l-5.2,-5.2c-1,-1 -2.6,-1 -3.6,-0.1z" />
    </Icon>
  );
};

export default IconScrollDown;
