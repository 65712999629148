import React, { FC, useCallback, useRef } from "react";
import styled from "styled-components";
import Container from "../Container";
import { useOnScreen } from "../hooks";
import Icon from "../Icon";
import { Theme } from "../theme.types";
import { Props } from "./Section.types";

const BackgroundImage = styled.div<{ $parallaxY: number }>`
  grid-area: 1/1;

  & .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: transform 0.05s;
    transform: translateY(${(p) => Math.abs(p.$parallaxY / 4)}px);
  }
`;

const BackgroundOverlay = styled.div`
  grid-area: 1/1;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
`;

const SectionContent = styled.div<{ $fullHeight?: boolean }>`
  position: relative;
  grid-area: 1/1;
  place-items: center;
  display: grid;
  padding: ${(p) => p.theme.unit(10)} 0;
  ${(p) => p.$fullHeight && "min-height: 100vh;"}
`;

const ScrollBelowButton = styled.button`
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  animation: float 3s ease-in-out infinite;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.6;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translatey(0px);
    }
    50% {
      transform: translateX(-50%) translatey(-20px);
    }
    100% {
      transform: translateX(-50%) translatey(0px);
    }
  }
`;

const SectionWrapper = styled.section<{
  $backgroundColor?: (theme: Theme["colors"]) => string;
  $hasBackgroundImage: boolean;
}>`
  display: grid;
  ${(p) => p.$hasBackgroundImage && `overflow: hidden;`}
  position: relative;
  ${(p) =>
    p.$backgroundColor &&
    `background-color: ${p.$backgroundColor(p.theme.colors)};`}
`;

export const Section: FC<Props> = ({
  fullHeight,
  backgroundColor,
  backgroundImage,
  backgroundOverlay,
  scrollBelowButton,
  children,
}) => {
  const ref = useRef<HTMLElement>(null);
  const { boundingClientRectY } = useOnScreen(ref, !!backgroundImage);

  const scrollBelowSection = useCallback(() => {
    const clientRect = ref.current?.getBoundingClientRect();
    window.scrollTo({
      top: window.scrollY + (clientRect?.bottom || 0),
      behavior: "smooth",
    });
  }, []);

  return (
    <SectionWrapper
      ref={ref}
      $backgroundColor={backgroundColor}
      $hasBackgroundImage={!!backgroundImage}
    >
      {backgroundImage && (
        <BackgroundImage $parallaxY={boundingClientRectY}>
          {backgroundImage}
        </BackgroundImage>
      )}
      {backgroundOverlay && <BackgroundOverlay />}
      <SectionContent $fullHeight={fullHeight}>
        <Container>{children}</Container>
      </SectionContent>
      {scrollBelowButton && (
        <ScrollBelowButton onClick={scrollBelowSection}>
          <Icon.IconScrollDown size={11} fill={() => "#fff"} />
        </ScrollBelowButton>
      )}
    </SectionWrapper>
  );
};
