exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-massasje-deep-tissue-tsx": () => import("./../../../src/pages/massasje/deep-tissue.tsx" /* webpackChunkName: "component---src-pages-massasje-deep-tissue-tsx" */),
  "component---src-pages-massasje-hot-stone-massasje-tsx": () => import("./../../../src/pages/massasje/hot-stone-massasje.tsx" /* webpackChunkName: "component---src-pages-massasje-hot-stone-massasje-tsx" */),
  "component---src-pages-massasje-index-tsx": () => import("./../../../src/pages/massasje/index.tsx" /* webpackChunkName: "component---src-pages-massasje-index-tsx" */),
  "component---src-pages-massasje-medisinsk-massasje-tsx": () => import("./../../../src/pages/massasje/medisinsk-massasje.tsx" /* webpackChunkName: "component---src-pages-massasje-medisinsk-massasje-tsx" */),
  "component---src-pages-massasje-sports-massasje-tsx": () => import("./../../../src/pages/massasje/sports-massasje.tsx" /* webpackChunkName: "component---src-pages-massasje-sports-massasje-tsx" */),
  "component---src-pages-massasje-strekking-tsx": () => import("./../../../src/pages/massasje/strekking.tsx" /* webpackChunkName: "component---src-pages-massasje-strekking-tsx" */),
  "component---src-pages-massasje-thai-massasje-tsx": () => import("./../../../src/pages/massasje/thai-massasje.tsx" /* webpackChunkName: "component---src-pages-massasje-thai-massasje-tsx" */),
  "component---src-pages-om-oss-tsx": () => import("./../../../src/pages/om-oss.tsx" /* webpackChunkName: "component---src-pages-om-oss-tsx" */),
  "component---src-pages-prisliste-tsx": () => import("./../../../src/pages/prisliste.tsx" /* webpackChunkName: "component---src-pages-prisliste-tsx" */)
}

